<template>
    <div class="faq">
        <Header :pageId1="pageId" :cId1="cId" />
        <div class="banner">
            <div class="container">
                <div class="title">Sell Your Car</div>
            </div>
        </div>
        
        <div class="main">

<div class="container">

    <div class="warpper">

        <div class="ddRow">
            <div class="ddCol-12">
                <h2>Sell Your Car Fast With the Assistance of Our Online Team</h2>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <p>When it’s time to <a href="./about.html">sell your car</a>, the last thing you need is a drawn-out process that takes up hours of your time before you even get a quote. Our team works fast to provide you with a quote once we have the necessary details and pay you within 24 hours. Choose Sell My Cars Fast for a lightning-fast selling service.</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>A Quick Guide to How to Sell Your Car for Cash Through Us</h3>
                <p>Why deal with complicated selling procedures if you can simply use our quick guide and sell your car within the week. Here are the steps to how you can sell your used car through our team:</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-6">
                <ul>
                    <li><strong>Send us the details of your vehicle.</strong> You can do this through our online form submission or contact us with the details via email. The details you need are the kilometres travelled, the year of manufacture, and the make and model of the car. You also need your proof of ownership and driver’s license.</li>
                    <li><strong>Await our team’s quote.</strong> Once you’ve submitted all the necessary details, our team will review it and investigate the vehicle’s current market value. Once we are satisfied that we have a fair offer, we will send you the quote.</li>
                    <li><strong>Agree or disagree.</strong> Now that you’ve received a quote from us, it’s time to agree or disagree to sell. If you’re happy with the quote we’ve sent, our team can have you paid within 24 hours. We know you want to sell fast, and we want to help you do it.</li>
                </ul>
            </div>
            <div class="ddCol-6">
                <img src="~@/assets/images/choose.png">
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>Excellent Reasons to Sell Your Car Online to Our Team</h3>
                <p>There are many reasons to sell your car to us, and we believe you should know about them to make a more informed decision. Here are some top reasons to consider selling to us:</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-6">
                <img src="~@/assets/images/row2_l.png">
            </div>
            <div class="ddCol-6">
                <ul>
                    <li><strong>You get your money that much faster.</strong> The selling process can be long and tedious when dealing with traditional dealerships. We know the top priority for you is to get rid of your vehicle and for someone to pay you for it. When you sell to us, we can pay you much faster.</li>
                    <li><strong>You have a car that you can’t seem to sell.</strong> We don’t care about the make or model of the vehicle. We will buy whatever you have for us. We will likely still make you an offer when other dealerships turn you down.</li>
                    <li><strong>You want to buy a new car.</strong> Why should you wait for the ideal buyer to come to you when you can just come to us? We believe that every offer we make is fair, especially given how fast we can purchase your vehicle.</li>
                </ul>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>How Our History Helps Us Sell Your Car Fast</h3>
                <p>We have spent considerable time in the vehicle industry as a licensed and registered car dealer. Our years of experience as a motor dealer allow us to make quick, fair offers for any vehicle our clients want to sell and pay them even faster.</p>
                <p><a href="./start.html">Submit an online form</a> today and start selling your vehicle.</p>
            </div>
        </div>
        
    </div>

</div>
        
</div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
    watch: {
        $route: {
            deep: true,
            handler(val) {
                this.pageId = val.query.id;
                this.cId = val.query.cId;
            },
        },
    },
    created() {
        this.pageId = this.$route.query.id;
        this.cId = this.$route.query.cId;
        this.pageName = this.$route.query.name;
    },
};
</script>
  
<style scoped lang="less">
.ddRow { font-family: Ubuntu-Regular, Ubuntu; color: rgba(0, 0, 0, 0.6); display: flex; align-items:center; margin: 1% 0; width: 100%; }
.ddRow h2, .ddRow h3 { font-weight: bold; color: #004AAD; margin: 4% 0 2% 0; text-align: center; }
.ddRow ul li { list-style-type: disclosure-closed; margin-bottom: 2%; margin-left: 4%; }
.ddRow img { width: 100%; }
.ddCol-12 { width: 100%; }
.ddCol-6 { width: 50%; padding: 2%; float: left; }
.ddRow:after { content: ""; display: table; clear: both; }
@media only screen and (max-device-width: 960px) { .ddRow {  display: block; } .ddCol-6 { width: 100%; } }
.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}
</style>
  